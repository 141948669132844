<script>
  import SeoHeader from '$lib/components/SeoHeader.svelte';
</script>

<SeoHeader
  data={{
    title: 'Point Poker - Team Estimation with Planning Poker',
    description:
      'A free, ad-free agile planning poker tool with multiple voting options, perfect for sprint estimation with remote or in-office teams.'
  }}
/>

<div class="hero flex-grow flex justify-center items-center">
  <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-24 space-y-8">
    <div class="text-center mx-auto">
      <h1 class="block font-medium text-4xl sm:text-5xl md:text-6xl lg:text-7xl">
        Welcome to Point Poker!
      </h1>
    </div>

    <div class="max-w-3xl text-center mx-auto">
      <p class="text-lg">
        A completely free, ad-free tool that makes agile sprint planning effortless. Enjoy quick
        setup, a simple user-friendly interface, and flexible voting options for any team
        environment.
      </p>
    </div>

    <div class="text-center">
      <a
        class="inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 shadow-lg shadow-transparent hover:shadow-blue-700/50 border border-transparent text-white text-sm font-medium rounded-full focus:outline-none focus:shadow-blue-700/50 py-3 px-6"
        href="/session/create"
      >
        Create a Session
        <svg
          class="shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg
        >
      </a>
    </div>
  </div>
</div>
